<template>
  <div class="se-card-box content">
    <search @submit="submitSearch" />

    <a-table
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
      size="middle"
      @change="sortChange"
    >
      <span slot="full_name" slot-scope="text, record">
        <router-link :to="{ name: 'gateway_details', params: { id: record.id } }">
          <span>{{ text | ellipsis }}</span>
        </router-link>
      </span>

      <span slot="actions" slot-scope="text, record">
        <a-space>
          <a v-if="record.customer_username!==''" class="se-red-link-color" @click="unbind(record)">解绑</a>
        </a-space>
      </span>
    </a-table>

    <pagination
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      :total-count="pagination.total_count"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Search from '@/views/gateway/Search'
import Pagination from '@/components/Pagination/index'
import { findGateways, unbindGateway } from '@/api/gateway'

export default {
  name: 'Gateways',
  components: {
    Search,
    Pagination
  },
  data() {
    return {
      data: [],
      loading: true,
      pagination: {
        total_count: 0
      },
      editingId: 0,
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length > 10) {
        return value.slice(0, 10) + '...'
      }
      return value
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '集抄器设备名称',
          dataIndex: 'full_name',
          scopedSlots: { customRender: 'full_name' },
          ellipsis: true
        },
        {
          title: '终端设备数量',
          dataIndex: 'terminal_num',
          sorter: true,
          sortOrder: this.sort.sort_field === 'terminal_num' ? this.sort.sort_order : false
        },
        {
          title: '身份标识',
          dataIndex: 'identity_no'
        },
        {
          title: '客户名称',
          dataIndex: 'customer_enterprise_name',
          ellipsis: true
        },
        {
          title: '客户账号',
          dataIndex: 'customer_username',
          ellipsis: true
        },
        {
          title: '客户联系人',
          dataIndex: 'customer_full_name',
          ellipsis: true
        },
        {
          title: '绑定时间',
          dataIndex: 'binding_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'binding_at' ? this.sort.sort_order : false
        },
        {
          title: '入库时间',
          dataIndex: 'created_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' }
        }
      ]
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      findGateways(Object.assign({}, this.query, this.sort)).then(res => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }
        this.loading = false
      })
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    unbind(record) {
      const vm = this

      this.$confirm({
        title: '提示',
        content: '确定解除集抄器"' + record.full_name + '"与客户"' + record.customer_full_name + '"的关联吗？解除后客户无法看到设备数据',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          vm.editingId = record.id
          unbindGateway(record.id).then((res) => {
            vm.editingId = 0
            if (res.code === 0) {
              vm.fetchData()
            }
          }).catch(() => {
            vm.editingId = 0
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  box-shadow: 0 0 10px #ccc;
  margin: 0 5px 0px 5px;
}
</style>
