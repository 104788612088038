<template>
  <div>
    <a-form :form="form" class="se-nested-search-form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :span="6">
          <a-form-item label="集抄器名称">
            <a-input
              v-decorator="['full_name', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="身份标识">
            <a-input
              v-decorator="['identity_no', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item label="客户名称">
            <a-input
              v-decorator="['customer_enterprise_name', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item v-show="expand" label="客户账号">
            <a-input
              v-decorator="['customer_username', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item v-show="expand" label="客户联系人">
            <a-input
              v-decorator="['customer_full_name', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button html-type="submit" type="primary">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
export default {
  name: 'Search',
  data() {
    return {
      expand: false,
      form: this.$form.createForm(this, { name: 'search' })
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    handleSearch(e) {
      e.preventDefault()
      this.$emit('submit', this.form.getFieldsValue())
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
