import request from '@/utils/request'

// findGateWayDevices 查找集抄器设备
export function findGateways(params) {
  return request({
    url: `/admin/gateways`,
    method: 'get',
    params: params
  })
}

// findGateway 查找集抄器设备详情信息
export function findGateway(id) {
  return request({
    url: `/admin/gateways/${id}/details`,
    method: 'get'
  })
}

// unbindGateway 解绑集抄器设备
export function unbindGateway(id) {
  return request({
    url: `/gateways/${id}/unbind`,
    method: 'get'
  })
}
